import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import BackToTop from '../components/navigation/backToTop';

const PageMl = ({ data }) => {
  const page = data.pageLegale;

  return (
    <Layout headerCssClass="has-background-white" mainCssClass={'page page-ml'}>
      <SEO title={page.title} pageLink={page.slug} />

      {/* HeroTitle */}
      <HeroTitle title={page.title} />
      <section
        className="section content"
        dangerouslySetInnerHTML={{
          __html: page.content.childMarkdownRemark.html,
        }}
      />

      {/* BackToTop */}
      <BackToTop />
    </Layout>
  );
};

export const query = graphql`
  query contentfulPageLegaleTemplate($contentfulId: String!) {
    pageLegale: contentfulPageLegale(contentful_id: { eq: $contentfulId }) {
      id
      node_locale
      slug
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      contentful_id
    }
  }
`;

export default PageMl;
